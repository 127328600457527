import {Carousel, Slide} from 'vue-carousel';
import {mapGetters} from "vuex";
import Vue from "vue";

export default {
  name: 'slider',
  props: ['sliders', 'items'],
  data() {
    return {
      currentSlide: 0,
      initialLoad: true,
    }
  },
  components: {
    Carousel,
    Slide
  },
  created() {
    setTimeout(() => {
      let test = document.querySelectorAll('.video-stream')
      console.log(test, 'test');
    }, 9000)
    // Vue.nextTick(() => {
    //   // console.log(this.$refs.sliderIframe.conten)
    //   let sliderIframe = this.$refs.sliderIframe
    //   if (sliderIframe) {
    //     Array.from(sliderIframe).forEach(el => {
    //       console.log(el, 'el');
    //     })
    //   }
    //
    // })
  },

  mounted() {

    // let fullSize = document.querySelectorAll('.fulSize')
    // if(fullSize){
    //
    //   let containerSlider = document.getElementById('containerSlider')
    //
    //
    //   containerSlider.classList.add('hidePadding')
    //   containerSlider.firstElementChild.classList.add('hidePadding')
    //
    //
    // }

  },
  methods: {
    changeArrow(value) {
      const carousel = this.$refs['slider-items'];
      const currentPage = carousel.currentPage;
      const pageCount = carousel.pageCount;
      if (value === 'prev') {
        currentPage !== 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
      } else {
        currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
      }
    },
    gotToSlide(index) {
      const carousel = this.$refs['slider-items'];
      carousel.goToPage(index)
    },
    pageChange(slide) {
      this.currentSlide = slide;
      this.initialLoad = false;
    },
    setFirstSlideActive() {
      const slides = document.querySelectorAll('.VueCarousel-slide');
      if (slides.length) {
        const firstSlide = slides[0];
        const className = 'VueCarousel-slide-active';

        if (firstSlide.classList) {
          firstSlide.classList.add(className);
        } else {
          firstSlide.className += ' ' + className;
        }
      }
    }
  }
}
